export const newsletter=[
  {
    "displayText": "2023 August",
    "filename": "./assets/documentation/newsletter/2023 August.pdf",
    "link": ""
  },
  {
    "displayText": "2023 DECEMBER",
    "filename": "./assets/documentation/newsletter/2023 DECEMBER.pdf",
    "link": ""
  },
  {
    "displayText": "2023 July",
    "filename": "./assets/documentation/newsletter/2023 July.pdf",
    "link": ""
  },
  {
    "displayText": "2023 November",
    "filename": "./assets/documentation/newsletter/2023 November.pdf",
    "link": ""
  },
  {
    "displayText": "2023 October",
    "filename": "./assets/documentation/newsletter/2023 October.pdf",
    "link": ""
  },
  {
    "displayText": "2023 September",
    "filename": "./assets/documentation/newsletter/2023 September.pdf",
    "link": ""
  },
  {
    "displayText": "2024 FEBRUARY",
    "filename": "./assets/documentation/newsletter/2024 FEBRUARY.pdf",
    "link": ""
  },
  {
    "displayText": "2024 January",
    "filename": "./assets/documentation/newsletter/2024 January.pdf",
    "link": ""
  }
]