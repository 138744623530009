<div>
    <my-nav style="z-index: 1;position: fixed;top:0px;right:0px;left:0px;">
    </my-nav>
</div>
<div style="padding:25px 5px 10px 5px;">
    <br>
    <br>
    <router-outlet></router-outlet>
</div>

<!-- Navigation -->

<!-- Footer -->
<footer class="bg-dark footer">
    <div class="container">
        <p class="m-0 text-center text-white">Copyright &copy; College of Pharmacy(poly), Akluj {{year}}.</p>
    </div>
    <!-- /.container -->
</footer>