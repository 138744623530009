<div class="row">
  <div class="col-md-12">
    <form id="loginForm">
    <div class="form-group">
      <small class="text-muted">{{responsetext}}<br></small>
      <label for="userEmail">Email</label>
      <input type="email" [(ngModel)]="username " name="email" class="form-control" id="userEmail" aria-describedby="emailHelp"
        placeholder="Enter email">
      <small id="emailHelp" class="form-text text-muted">Email address is your username</small>
    </div>
    <div class="form-group">
      <label for="exampleInputPassword1">Password</label>
      <input type="password" name="password" [(ngModel)]="password" class="form-control" id="password" placeholder="Password">
    </div>
    <small class="text-info">** Login portal is available for institute admin</small>
    <button type="button" (click)="loginUser()" class="btn btn-primary" style="float: right;"[disabled]="loginButtondisabled">Submit</button>
  </form>
  </div>
</div>