export const academic=[
  {
    "displayText": "Anti Discrimination Cell",
    "filename": "./assets/documentation/academic/Anti Discrimination Cell.pdf",
    "link": ""
  },
  {
    "displayText": "Anti Ragging Comittee",
    "filename": "./assets/documentation/academic/Anti Ragging Comittee.pdf",
    "link": ""
  },
  {
    "displayText": "Gender Sensitization Cell",
    "filename": "./assets/documentation/academic/Gender Sensitization Cell.pdf",
    "link": ""
  },
  {
    "displayText": "Governing Body",
    "filename": "./assets/documentation/academic/Governing Body.pdf",
    "link": ""
  },
  {
    "displayText": "Result",
    "filename": "./assets/documentation/academic/Result.pdf",
    "link": ""
  },
  {
    "displayText": "Training and Placement Cell",
    "filename": "./assets/documentation/academic/Training and Placement Cell.pdf",
    "link": ""
  }
]