export const faculty_details = [
  {
    name: "Mr. Nanasahe B. Devadakar	",
    designation: "Principal",
    qualification: "M. Pharmacy",
    img: "./assets/img/faculty/Devadakar.png",
  },
  {
    name: "Mr. Vinay D. Gaikwad",
    designation: "Lecturer",
    qualification: "M. Pharmacy",
    img: "./assets/img/faculty/vinay.png",
  },
  {
    name: "Mr. Vishal B. Gaikwad	",
    designation: "Lecturer",
    qualification: "M. Pharmacy",
    img: "./assets/img/faculty/vbgaikwad.jpg",
  },
  {
    name: "Ms. Pranali A. Tate-Deshmukh	",
    designation: "Lecturer",
    qualification: "M. Pharmacy",
    img: "./assets/img/faculty/patate.png",
  },
  {
    name: "Ms. Monika R. Doshi",
    designation: "Lecturer",
    qualification: "M. Pharmacy",
    img: "./assets/img/faculty/doshi.png",
  },
  {
    name: "Ms. Madhavi N. Khandekar",
    designation: "Lecturer",
    qualification: "B. Pharmacy",
    img: "./assets/img/faculty/khandekar.png",
  },
  {
    name: "Ms. Pooja K. Gaikwad",
    designation: "Lecturer",
    qualification: "B. Pharmacy",
    img: "./assets/img/faculty/pooja.png",
  },
  {
    name: "Mr. Amit S. Sargar",
    designation: "Lecturer",
    qualification: "B. Pharmacy",
    img: "./assets/img/faculty/sargar.png",
  },
];

export const nonteaching = [
  {
    name: "Mr. Patil R.P.",
    designation: "Lab Technician	",
    qualification: "B.sc, D. Pharm",
    img: "./assets/img/faculty/patilrp.png",
  },
  {
    name: "Mr. Mahadar S.B.",
    designation: "Lab Technician	",
    qualification: "D. Pharm",
    img: "./assets/img/faculty/mahadar.png",
  },
  {
    name: "Mr. Shinde U.D.",
    designation: "Asst. Librarian	",
    qualification: "B.Lib.",
    img: "./assets/img/faculty/shinde.png",
  },
  {
    name: "Mr. Devkar S.S.",
    designation: "Clerk",
    qualification: "B. Com",
    img: "./assets/img/faculty/placeholder.png",
  },
];

export const attendant = [
  {
    name: "Mr. Mali. M.B.",
    designation: "LabAttendant",
    qualification: "",
    img: "./assets/img/faculty/placeholder.png",
  },
  {
    name: "Mr. Bhudhavale A.M.",
    designation: "LabAttendant",
    qualification: "",
    img: "./assets/img/faculty/placeholder.png",
  },
  {
    name: "Mr. Mashal M.S.",
    designation: "Peon",
    qualification: "",
    img: "./assets/img/faculty/placeholder.png",
  },
  {
    name: "Mr. Chavan B.P.",
    designation: "Peon",
    qualification: "",
    img: "./assets/img/faculty/placeholder.png",
  },
  {
    name: "Mr. Raut S.L.",
    designation: "Peon",
    qualification: "",
    img: "./assets/img/faculty/placeholder.png",
  },
];
