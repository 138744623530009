<div class="container">
  <div class="card">

    <div class="card-body">
      <div class="card-title">
       <h5>Gallery</h5>  <hr>
      </div>
      <br>
      <div class="container">
        <div class="row">
          <div class="col-lg-3" *ngFor='let image of images; let i=index'>
            <div class="grid" style="text-align:center">
              <img [src]="image.src" class="icon" alt='' (click)="displayImage(i)" data-toggle="modal" data-target="#exampleModal" style="width: 100%;"
                (load)="removeSpinner('img'+i)">
              <div id="img{{i}}" class="image-overlay">
                <img class="spinner" src="https://loading.io/spinners/vortex/lg.vortex-spiral-spinner.gif" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Button trigger modal -->
<!-- Modal -->
<div class="modal fade " id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="prevImage" (click)="prevImage()">
         <!--  <button class="navBtnLeft">left</button> -->
          <img class="navBtnLeft" src="./assets/img/icon/left.png" height="50px" width="50px">
        </div>
        <div>
          <img src="{{selectedImage?.src}}" alt="" class="popupImage" (load)="imageLoad()">
        </div>
        <div class="nextImage" (click)="nextImage()">
            <img class="navBtnLeft" src="./assets/img/icon/right.png" height="50px" width="50px">
        </div>

      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>