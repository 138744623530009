<div class="row" style="height: 75vh;overflow-y: scroll;">
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">No</th>
        <th scope="col">Title</th>
        <th scope="col">Reporter</th>
        <th scope="col">Status</th>
        <th scope="col"  *ngIf="isLoggedIn">Details</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of historyList; index as i;">
        <td>{{item.grNo}}</td>
        <td>{{item.gr_title}}</td>
        <td>{{item.name}}</td>
        <td>{{item.gr_status}}</td>
        <td *ngIf="isLoggedIn">
          <button class="btn btn-sm btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg" (click)="viewDetailsofGrievance(i)">View Details</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>


<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <div class="modal-content" *ngIf="activeGrievance">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">{{activeGrievance.gr_title}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
          <div class="form-group row">
          <div class="col-md-6" >
              <Label>Reporter</Label>
              <pre>{{activeGrievance.name}}</pre>
          </div>
          <div class=" col-md-6">
              <Label>Contact No</Label>
              <pre>{{activeGrievance.contact}}</pre>
          </div>
        </div>

          <div class="form-group">
            <label for="details">Details</label>
            <textarea name="details" id="details"  class="form-control" disabled>
                  {{activeGrievance.gr_details}}
            </textarea>
          </div>
          <div class="form-group">
            <label for="Comments">Comments</label>
            <textarea name="Comments" id="Comments"  class="form-control">
                {{activeGrievance.gr_comments}}
            </textarea>
          </div>
          <div class="form-group">
            <label for="status">Status</label>
            <select id="status" class="form-control" >
              <option value="PENDING">PENDING</option>
              <option value="RESOLVED">RESOLVED</option>
            </select>
          </div>
          <small class="text-muted">Created on: {{activeGrievance.gr_create_date | date:'dd/MMM/yyyy'}}</small>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>