<div class="container">

  <div class="card">
    <div class="card-body">
      <h5 class="card-title">About us</h5>
      <hr>
      <br>
      <div class="row">
        <div class=" col-lg-4  text-center center-block" style="background:none;">
          <img alt="Founder" src="./assets/img/college/Maharshi.jpg" class="img-responsive" style="max-height:134px;margin: 0 auto;border-radius: 0px 10px;border: 2px solid;box-shadow: 0px 0px 10px;">
          <p>
            <strong class="text-info">Late Shri. Shankarrao Mohite-Patil</strong>
            <br>
            <small>Founder,</small>
            <br>
            <small>Shikshan Prasarak Mandal, Akluj.</small>
          </p>
        </div>

        <div class=" col-lg-4 text-center center-block" style="background:none;">
          <img alt="Founder" src="./assets/img/college/logo.png" class="img-responsive" style="max-height:134px;margin: 0 auto;border-radius: 0px 10px;border: 2px solid;box-shadow: 0px 0px 10px;">
          <p>
            <br>
            <strong class="text-info">Shikshan Prasarak Mandal, Akluj</strong>
            <br>

          </p>
        </div>

        <div class=" col-lg-4  text-center" style="background:none;">
          <img alt="Founder" src="./assets/img/college/president.png" class="img-responsive" style="max-height:134px;margin: 0 auto;border-radius: 0px 10px;border: 2px solid;box-shadow: 0px 0px 10px;">
          <p>
            <strong class="text-info">Hon. Shri Jaysinh Mohite-Patil</strong>
            <br>
            <small>President,</small>
            <br>
            <small>Shikshan Prasarak Mandal, Akluj.</small>
          </p>
        </div>

       
        <div class="col-lg-12 text-justify ">
          <p>
            Late Sahakar Maharshi Shankarrao Mohite-Patil founder member established Shikshan Prasarak Mandal Akluj in 1948 with the
            view changing the standards of society in all respects through education. Shikshan Prasarak Mandal Akluj was
            started College of Pharmacy (Poly.) in 1993. Now this institute is one of the leading pharmacy educational institute in
            Maharashtra.
          </p>

          <p>Shikshan Prasarak Mandal (SPM) is one of the leading educational institutions in Maharashtra. It was founded by
            a dedicated and committed Late Shankarrao Mohite-Patil in 1948. The institution has evolved and made a mark in
            the arena of education allover Maharashtra due to the ceaseless efforts of the teaching and administrative staff
            and the foresight of the management.
          </p>
          <p>
            SPM runs different educational institutions, which include Preprimary, Primary, Secondary, higher Secondary education and
            technical courses covering pharmacy, agriculture and engineering.</p>
          <p>
            SPM has made rapid progress under the foresighted leadership of its Chairman Mr. Jaysinh S. Mohite-Patil. SPM's College of
            Pharmacy, Akluj was started in the year 1993 by our beloved chairman Mr.Jaysinh S. Mohite-Patil. </p>
        </div>

        <div class="col-lg-12">
          <table class="table  bg-white">
            <tbody><tr>
              <td style="width: 50%;">Year of Establishment</td>
              <td>1993</td>
            </tr>
            <tr>
              <td>Approved By</td>
              <td>PCI, AICTE, DTE, Govt. of Maharashtra</td>
            </tr>
            <tr>
              <td>DTE Institute Code</td>
              <td>6401</td>
            </tr>
            <tr>
              <td>MSBTE Institute Code</td>
              <td>0252</td>
            </tr>
            <tr>
              <td>e-mail </td>
              <td>0252principal@msbte.com<br>
                0252office@msbte.com
              </td>
            </tr>
          </tbody></table>
        </div>


      </div>
    </div>
  </div>

  <br>


</div>