import { Component } from '@angular/core';
import { importantLinks } from '../../constant/implinks.constant';
import { newsletter } from 'src/app/constant/auto/newsletter.constant';
import { academic } from 'src/app/constant/auto/academic.constant';

@Component({
  selector: 'app-dashboard',
  templateUrl: './app-dashboard.component.html',
  styleUrls: ['./app-dashboard.component.css']
})
export class AppDashboardComponent {
  Totaldate = { day: '', date: '', month: '', year: '', time: '' }

  readonly importantLinks;
  readonly academicLinks = academic;
  readonly newsletter =newsletter;
  constructor() {
    this.importantLinks = importantLinks;
  }
}
