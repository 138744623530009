<div class="container">

  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Admission</h5>
      <hr>
      <br>
      <div class="row">
        <div class="col-lg-12">
          <div class="">
            <div class="">
              <h5 class="card-title">
                Admission to First Year of Post HSC Diploma Course In Pharmacy For Academic Year 2021-22
                <small>Institute Level Quota</small>
              </h5>
            </div>
            <div class="card-body">
         <!--      <table class="table table-bordered table-sm">
                <thead class="thead-light">
                  <tr>
                    <th>Sr. No</th>
                    <th>Activity</th>
                    <th>Schedule</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td> Sale of Information Brochure along with Application form
                      <br>  Submission of Filled application form
                    </td>
                    <td>08/10/2021 10 am to 12/10/2021 5.00pm</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Display of Provisional merit list</td>
                    <td>13/10/2021at 11.00 am</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Grievance to be submitted at institute</td>
                    <td>14/10/2021 up to 5.00 pm</td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td>Display of final merit list</td>
                    <td>15/10/2021 at 11.00 am</td>
                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td>Reporting to institute as per merit list</td>
                    <td>16/10/2021 to 20/10/2021 upto 5.00 pm</td>
                  </tr>
                </tbody>
              </table> -->
            <img src="./assets/documentation/admission/ilq.jpg" alt="" srcset="">
            </div>
          </div>


          <div class="">
            <div class="">
              <h5 class="card-title">
                Admission to First Year of Post HSC Diploma Course In Pharmacy For Academic Year 2020-21 (No. of
                Vacancies = 10)
                <small>Vacancy of ACAP</small>
              </h5>
            </div>
            <div class="card-body">
              <img src="./assets/documentation/admission/acap.jpg" class="" alt="" srcset="" width="100%">
              <!-- <table class="table table-bordered table-sm">
                <thead class="thead-light">
                  <tr>
                    <th>Sr. No</th>
                    <th>Activity</th>
                    <th>Schedule</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td> Sale of Information Brochure along with Application form
                      <br>  Submission of Filled application form
                    </td>
                    <td>13/10/2021 to 19/10/2021 upto 05.00 pm</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Display of Provisional merit list</td>
                    <td>20/10/2021 at 11.00 am</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Grievance to be submitted at institute</td>
                    <td>21/10/2021 at 5.00 pm</td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td>Display of final merit list</td>
                    <td>05/01/2021 at 4.00pm</td>
                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td>Reporting to institute as per merit list</td>
                    <td>22/10/2021 to 25/10/2021 upto 5.00 pm</td>
                  </tr>
                </tbody>
              </table> -->
            </div>
          </div>

          <div class="">
            <div class="">
              <h5 class="card-title">Important Links</h5>
            </div>
            <div class="card-body">
              <ul>
                <li>
                  <a target="_blank" href="./assets/documentation/adv/Advertise.pdf">Admission advertise 2022-23</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>