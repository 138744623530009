<div class="container">
  <div class="card">
    <div class="card-body bg-setup">
      <h5 class="card-title">Principal's Desk</h5>
      <hr>
      <br>
      <p class="card-text" style="font-family: 'Sacramento', cursive;
      font-size: 35px;
      line-height: 45px;">Dear Students, <br> College of Pharmacy (POLY), Akluj has so far produced 22 batches of
        Pharmacists. Most of them are serving
        the society as pill-dispenser rather than " Managed Health Care Providers ". The College has made overall
        progress
        in the field of Technical Education as one of the leading institutions in the state of Punjab. The college has
        maintained
        high standards of discipline & achieved excellence in the field of Pharmacy education and has also taken
        interest
        in community welfare related activities such as environmental management, public health care, drug information
        and
        patient education etc. All this has been visible because of continuous efforts,hard work and co-operation of
        management,
        staff students and NGO's. Health has been of prime importance to mankind since pre-historic times.
        Pharmaceutical
        Scientists have to play an important role in attaining a better quality of life. I am sure that the combination
        of
        vision of our management, complete dedication of our faculty and efforts of our students will work towards
        setting
        global standards in Pharmaceutical education.</p>

      <br>
      <br>
      <div class="text-right">
        <h5>Mr. Devadkar N.B. </h5>
        <small>Principal, <br> College of Pharmacy(poly),Akluj</small>
      </div>
    </div>
  </div>
</div>