<button type="button" class="btn btn-primary btn-sm" data-toggle="modal" [attr.data-target]="'#'+ id" style="float: right;">
    View
  </button>
  
  <!-- Modal -->
  <div class="modal fade" id="{{id}}" tabindex="-1" role="dialog" [attr.aria-labelledby]="id+'-Title'"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" [id]="id+'Title'">{{title}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <embed [src]='photoURL(url)' type="application/pdf" width="100%" height="100%">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>