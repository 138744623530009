


<div class="container">
  <div class="row" >
      <h3>Grievance Redressal Portal</h3>
    <div class="col-lg-12">
        <small class="text-info" *ngIf="username">Hi, {{username}}</small>
        <button class="btn btn-sm btn-primary pull-right" style="float:right"  data-toggle="modal" data-target="#exampleModal" *ngIf="!username">Login</button>
        <button class="btn btn-sm btn-primary pull-right" style="float:right"  (click)="logout()" *ngIf="username">Logout</button>
    </div>

    <div class="col-lg-12">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">My Portal</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="history-tab" data-toggle="tab" href="#history" role="tab" aria-controls="history" aria-selected="false">History</a>
            </li>
            
          </ul>
         
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <br> 
              <div class="card">
                    <h5 class="card-header">Mechanism for Grievances Redressal</h5>
                    <div class="card-body">
                      <!-- <h5 class="card-title">Special title treatment</h5> -->
                      <p class="card-text">
                          College of Pharmacy has created a mechanism for redressal of student's grievances related to academic and non-academic matters, such as assessment, victimization, attendance, charging of fees, conducting of examinations, harassment by colleague students or teachers etc. There are Grievance Redressal Committees at the Programme/Institute/University levels to deal with the grievances of the students.
                      </p>
                    </div>
                  </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
              <br>
                <div class="card">
                    <h5 class="card-header">Lodge your Grievance</h5>
                    <div class="card-body">
                        <app-add (command)="requestForUpdate($event)"></app-add>
                    </div>
                  </div>
                
            </div>
            <div class="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
                <app-student-history [historyList]="complaintList" [isLoggedIn]="this.isLoggedIn" ></app-student-history>
            </div>
            <div class="tab-pane fade" id="teacher-profile" role="tabpanel" aria-labelledby="teacher-profile-tab">teacher-profile</div>
            <div class="tab-pane fade" id="report" role="tabpanel" aria-labelledby="report-tab">report</div>
          </div>

    </div>
  </div>
</div>
  
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Login</h5>
          <button id="popupclose" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
        <app-login (user)="manageProfile($event)"></app-login>
        </div>
      </div>
    </div>
  </div>