<div class="container">

  <div class="card">
    <div class="card-body">

      <div class="row">
        <img src="./assets/img/icon/under-construction.png" class="img" style="max-height: 250px;">
      </div>
    </div>
  </div>
</div>