import { Component, OnInit } from '@angular/core';
import { faculty_details, nonteaching, attendant } from '../../../../constant/about-us.constant';

@Component({
  selector: 'app-faculty',
  templateUrl: './faculty.component.html',
  styleUrls: ['./faculty.component.css']
})
export class FacultyComponent implements OnInit {
  readonly faculty_details = faculty_details;
  readonly nonteaching = nonteaching;
  readonly attendant = attendant;
  constructor() {

  }

  ngOnInit() {

  }

}
