<div class="container">


  <div class="">
    <h5>Faculty Details</h5>
    <hr>
  </div>
  <div class="row">

    <div class="col-xl-6" *ngFor="let member of faculty_details">
      <mat-card class="example-card">
        <mat-card-header>
          <mat-card-title-group>
            <img mat-card-md-image [src]="member.img" [alt]="member.name">
            <mat-card-title>{{member.name}}</mat-card-title>
            <mat-card-subtitle>{{member.designation}}</mat-card-subtitle>
            <mat-card-subtitle>{{member.qualification}}</mat-card-subtitle>

          </mat-card-title-group>
        </mat-card-header>
        <mat-card-content>
          <!--       {{longText}} -->
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <br>
  <div class="">
    <h5 color="primary">Non-Teaching Faculty</h5>
    <hr>
  </div>
  <div class="row">
    <div class="col-xl-6" *ngFor="let member of nonteaching">
      <mat-card class="example-card">
        <mat-card-header>
          <mat-card-title-group>
            <img mat-card-md-image [src]="member.img" [alt]="member.name">
            <mat-card-title>{{member.name}}</mat-card-title>
            <mat-card-subtitle>{{member.designation}}</mat-card-subtitle>
            <mat-card-subtitle>{{member.qualification}}</mat-card-subtitle>

          </mat-card-title-group>
        </mat-card-header>
        <mat-card-content>
          <!--       {{longText}} -->
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <br>
  <div class="">
    <h5>Non-Teaching Staff</h5>
    <hr>
  </div>
  <div class="row">

    <div class="col-xl-6" *ngFor="let member of attendant">
      <mat-card class="example-card">
        <mat-card-header>
          <mat-card-title-group>
            <img mat-card-md-image [src]="member.img" [alt]="member.name">
            <mat-card-title>{{member.name}}</mat-card-title>
            <mat-card-subtitle>{{member.designation}}</mat-card-subtitle>
            <mat-card-subtitle>{{member.qualification}}</mat-card-subtitle>

          </mat-card-title-group>
        </mat-card-header>
        <mat-card-content>
          <!--       {{longText}} -->
        </mat-card-content>
      </mat-card>
    </div>
  </div>

</div>