<nav class="navbar navbar-expand-lg navbar-light bg-custom">
  <a class="navbar-brand text-warning" href="#">{{applicationName}}</a> 
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="navbar-nav">
      <li *ngFor="let menu of menuList" class="nav-item dropdown">
          <a *ngIf="!menu.subMenu" class="nav-link menu-link text-white" [routerLink]="menu.link">{{menu.name}}</a>
          <a *ngIf="menu.subMenu" class="nav-link  text-white dropdown-toggle" data-toggle="dropdown"  role="button" aria-haspopup="true"
            aria-expanded="false">{{menu.name}}</a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a *ngFor="let enu of menu.subMenu" class="dropdown-item" [routerLink]="enu.link">{{enu.name}}</a>
        </div>
      </li>
    </ul>
  </div>
</nav>