import { Component, Input, KeyValueDiffers, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'


@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnInit {

  private _title: string;
  public get title(): string {
    return this._title;
  }
  @Input()
  public set title(value: string) {
    this._title = value;
  }
  private _url: string;
  public get url(): string {
    return this._url;
  }

  @Input()
  public set url(value: string) {
    this._url = value;
  //  console.log(value)
  }

 
  private _id: string;
  public get id(): string {
    return this._id;
  }
  @Input()
  public set id(value: string) {
    this._id = value;
  }

  constructor(private _sanitizer: DomSanitizer) {

  }
  photoURL(url: string) {
    (this._sanitizer.bypassSecurityTrustUrl(url))
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit(): void {
  }

}
